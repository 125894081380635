import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { Articles } from "../../Data/Data";
import { Link } from "react-router-dom";

function ArticleBanner() {
  return (
    <Fragment>
      <div className="container governancemain motor">
        <div className="row">
          <div className="col-sm-12 heads govbanner">
            <Fade up delay={500}>
              <h2 className="oswald">Articles</h2>
            </Fade>
          </div>

          <div className="col-sm-12 heads text news">
            <div className="row ArticlesAndBlogs">
          
               {Articles.map((data) => {
                const trimmedContent = data.content.split(" ").slice(0, 26).join(" ") + (data.content.split(" ").length > 26 ? "..." : "");
                return (
                  <div className="col-sm-4">
                    <Fade right delay={500}>
                      <Link
                        to={`/articles/${data.key}`}
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <div className="menusildes" key={data.key}>
                          <Flip top delay={500}>
                            {" "}
                            <img src={data.feature} alt="" />
                          </Flip>
                          <Fade up delay={500}>
                            <div dangerouslySetInnerHTML={{ __html: trimmedContent }} />
                          </Fade>
                        </div>
                      </Link>
                    </Fade>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ArticleBanner;
